import { createApp } from 'vue';
import App from './App.vue';
import { createRouter, createWebHistory } from 'vue-router';
import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';

// Import components
import MainMenu from './components/Layout/MainMenu.vue';
import Signature from './components/layout/Signature.vue';
import MainFooter from './components/Layout/MainFooter.vue';
import Tools from './components/Tools.vue';
import Projects from './components/Projects.vue';
import Me from './components/Me.vue';
import Skills from './components/Skills';
import ContentToggler from './components/ui-elements/ContentToggler.vue'
import ToolCard from './components/ui-elements/ToolCard.vue';

// Import components for Shuffle Standup
import MainStandup from './standup/MainStandup.vue'
import Name from './standup/Name.vue'
import TeamDialog from './standup/TeamDialog.vue'
import Rewards from './standup/Rewards.vue'
import Notes from './standup/Notes.vue'
import Faces from './standup/Faces.vue'
import Login from './standup/Login.vue'

// Localization setup
import { createI18n } from 'vue-i18n';
import en from '../lang/en/messages.js'; // Adjust these paths
import fr from '../lang/fr/messages.js';

const messages = {
  en,
  fr
};

const i18n = createI18n({
  locale: 'en',
  messages,
});

// routes
const routes = [
  {
    props: true,
    path: '/',
    component: Skills,
    name: 'home',
    meta: {
      title: "Home Page",
    }
  },
  {
    path: '/skills',
    component: Skills,
    name: 'skills',
    meta: {
      title: "My Skills",
    }
  },
  {
    path: '/tools',
    component: Tools,
    name: 'tools',
    meta: {
      title: "My Tools",
    }
  },
  {
    path: '/projects',
    component: Projects,
    name: 'projects',
    meta: {
      title: "My Projects",
    }
  },
  {
    path: '/me',
    component: Me,
    name: 'me',
    meta: {
      title: "About Me",
    }
  },
  {
    path: '/standup',
    component: MainStandup,
    name: 'MainStandup',
    meta: {
      title: "Shuffle Standup",
      noLayout: true,
    }
  },
]

// Create router instance
const router = createRouter({
  history: createWebHistory(),
  routes, // Your routes array
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // If there's a saved scroll position (for example, from navigating back),
      // scroll to that position
      return savedPosition;
    } else if (to.hash) {
      // If there's a hash in the URL, scroll to that element
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    } else {
      // Default scroll behavior: start at the top of the page
      return { top: 0 };
    }
  },
});

const app = createApp(App);

app.use(mavonEditor);
app.use(i18n);
app.use(router);

// Register components
app.component('mainmenu', MainMenu);
app.component('signature', Signature);
app.component('mainfooter', MainFooter);
app.component('tools', Tools);
app.component('projects', Projects);
app.component('me', Me);
app.component('contenttoggler', ContentToggler);
app.component('toolcard', ToolCard);

// Register components for Shuffle Standup
app.component('mainstandup', MainStandup);
app.component('name', Name);
app.component('teamdialog', TeamDialog);
app.component('faces', Faces);
app.component('notes', Notes);
app.component('rewards', Rewards);
app.component('login', Login);

app.mount('#app');
