<template>
	<div>
		<div class="container text-center">
			<svg xmlns:xlink="http://www.w3.org/1999/xlink"	width="2000" height="2000" class="draw-line-container-1">
				<defs>
					<pattern
						id="hexagons"
						width="50"
						height="43.4"
						patternUnits="userSpaceOnUse"
						patternTransform="scale(20)"
						stroke-width="0.04"
						stroke="#ffd998"
						fill="none"
						class="draw-line-1">
						<polygon points="24.8,22 37.3,29.2 37.3,43.7 24.8,50.9 12.3,43.7 12.3,29.2" id="hex" />
						<use xlink:href="#hex" x="25" />
						<use xlink:href="#hex" x="-25" />
						<use xlink:href="#hex" x="12.5" y="-21.7" />
						<use xlink:href="#hex" x="-12.5" y="-21.7" />
					</pattern>
				</defs>
				<rect width="100%" height="100%" fill="url(#hexagons)" />
			</svg>
			<h1 class="signature-container">
				<div>
					<div class="AL-font A"></div>
					<span class="AL-font ntony">NTONY</span>
				</div>
				<div>
					<div class="AL-font L"></div>
					<span class="AL-font ourenco">OURENCO</span>
				</div>
			</h1>
			<h2 class="web-int">
				<span v-html="$t('signature_integration')"></span>
				<span class="and" v-html="$t('signature_and')"></span>
				<span v-html="$t('signature_webdesign')"></span>
			</h2>
		</div>
	</div>
</template>
<script>
	export default {

	}
</script>
