<template>
	<div class="tool-card-container">
		<div class="content content-fluid">
			<h2>
				<span v-html="$t('tools')"></span>
				<span class="icons Ax"></span>
				<span class="note" v-html="$t('some_of_them')"></span>
			</h2>
			<toggle v-for="(toggle, index) in toggles" :key="index" :toggle="toggle" :index="index"></toggle>
			<div class="btn-send-me-container">
				<router-link :to="{path: '/projects', hash: '#content'}"  class="link" exact>
					<div class="btn-send-me next inline">
						<span class="text" v-html="$t('next')"></span>
						<span class="icons Arrow"></span>
					</div>
				</router-link>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		props: ["showtools"],
		data() {
			return {}
		},
		computed: {
			toggles() {
				return [
					[{
							id: "6",
							title: "GitHub",
							link: "https://github.com/",
						},
						{
							id: "12",
							title: "VueJS",
							link: "https://vuejs.org/",
						},
						{
							id: "12",
							title: "KendoReact",
							link: "https://www.telerik.com/kendo-react-ui",
						},
						{
							id: "19",
							title: "Rails",
							link: "https://rubyonrails.org/",
						},
						{
							id: "11",
							title: "Bootstrap",
							link: "https://getbootstrap.com/",
						},
						{
							id: "20",
							title: "JQuery",
							link: "https://jquery.com/",
						},
						{
							id: "21",
							title: "Summernote",
							link: "https://summernote.org/",
						},
						{
							id: "10",
							title: "MomentJS",
							link: "https://momentjs.com/",
						},
						{
							id: "16",
							title: "Highcharts",
							link: "https://www.highcharts.com/",
						},
						{
							id: "6",
							title: "VSCode",
							link: "https://code.visualstudio.com/",
						},
						{
							id: "17",
							title: "Ag-grid",
							link: "https://www.ag-grid.com/",
						},
						{
							id: "12",
							title: "Docker",
							link: "https://www.docker.com/",
						},
						{
							id: "22",
							title: "Stylelint",
							link: "https://stylelint.io/",
						},
					],
					[{
							id: "18",
							title: "Slack",
							link: "https://slack.com",
						},
						{
							id: "7",
							title: "ClickUp",
							link: "https://app.clickup.com/",
						},
						{
							id: "7",
							title: "Jira",
							link: "https://www.atlassian.com/fr/software/jira",
						},
					],
					[{
							id: "13",
							title: "Sketch",
							link: "https://www.sketch.com/",
						},
						{
							id: "14",
							title: "Illustrator",
							link: "https://www.adobe.com/",
						},
						{
							id: "3",
							title: "Icomoon",
							link: "https://icomoon.io/",
						},
						{
							id: "5",
							title: "G-Webfonts",
							link: "http://google-webfonts-helper.herokuapp.com/",
						},
					],
					[{
							id: "1",
							title: "Unsplash",
							link: "https://unsplash.com/",
						},
						{
							id: "2",
							title: "Dribbble",
							link: "https://dribbble.com/",
						},
						{
							id: "9",
							title: "ChecklistDesign",
							link: "https://www.checklist.design/",
						},
						{
							id: "4",
							title: "Enjoycss",
							link: "http://enjoycss.com/",
						},
						{
							id: "15",
							title: "Youtube",
							link: "https://www.youtube.com/",
						},
					],
					[{
							id: "8",
							title: "StackOverflow",
							link: "https://stackoverflow.com/",
						},
					],
				]
			},
		},
	}
</script>