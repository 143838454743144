<template>
    <div class="project-card-container">
        <div class="content">
            <h2>
                <span v-html="$t('projects')"></span>
                <span class="icons Authorization-success"></span>
            </h2>

            <div class="content-subtitle" @click="toggleProjects" :class="showProjects ? 'active' : ''">
                <h3>
                    <span class="icons Dirrection"></span>
                    <span class="text" v-html="$t('projects_sites')"></span>
                </h3>
                <div class="icons Arrow-2" :class="showProjects ? 'down' : ''"></div>
                <div class="content-section" v-if="showProjects">
                    <a v-for="(project, index, key) in projects" :key="key" target="_blank" :href="project.link"
                        class="project-card" :class="project.code" @click.stop>
                        <div class="project-content">
                            <div class="project-image"></div>
                            <h4 class="title"><span v-html="project.title"></span> <span class="icons Surfing"></span></h4>
                            <div class="description" v-html="$t(`projects_title_${project.id}`)"></div>
                            <div class="status" v-if="project.status" v-html="project.status"></div>
                        </div>
                    </a>
                </div>
                <div class="content-section" v-if="showProjects">
                    <a v-for="(subProject, index, key) in subProjects" :key="key" target="_blank" :href="subProject.link"
                        class="project-card" :class="subProject.code" @click.stop>
                        <div class="project-content">
                            <div class="project-image"></div>
                            <h4 class="title"><span v-html="subProject.title"></span> <span class="icons Surfing"></span></h4>
                            <div class="description" v-html="$t(`projects_title_${subProject.id}`)"></div>
                            <div class="status" v-if="subProject.status" v-html="subProject.status"></div>
                        </div>
                    </a>
                </div>
            </div>

            <div class="content-subtitle" @click="toggleDesigns" :class="showDesigns ? 'active' : ''">
                <h3>
                    <span class="icons Image"></span>
                    <span class="text" v-html="$t('projects_illustrations')"></span>
                </h3>
                <div class="icons Arrow-2" :class="showDesigns ? 'down' : ''"></div>
                <div class="content-section" v-if="showDesigns">
                    <a v-for="(design, index) in designs" :key="index" :class="'project-card ' + design.code +  [ design.link ? '' : ' disabled' ]" :href="design.link || null" :target="design.link ? '_blank' : null" @click.stop>
                        <div class="project-content">
                            <div :class="'design-viewer ' + design.code + [design.whiteBg ? ' white-bg': '']"></div>
                            <h4 class="title"><span v-html="design.title"></span> <span v-if="design.link" class="icons Surfing"></span></h4>
                            <div class="description" v-html="design.id? $t(`projects_title_${design.id}`) : design.description"></div>
                        </div>
                    </a>
                </div>
            </div>

            <div class="btn-send-me-container">
                <router-link :to="{path:'/me', hash: '#content'}" >
                    <div class="btn-send-me next inline">
                        <span class="text" v-html="$t('next')"></span>
                        <span class="icons Arrow"></span>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                showProjects: true,
                showDesigns: false,
                showTest: false,
            }
        },
        methods: {
            toggleProjects() {
                this.showProjects = !this.showProjects;
            },
            toggleDesigns() {
                this.showDesigns = !this.showDesigns;
            },
            toggleTest() {
                this.showTest = !this.showTest;
            },
        },
        computed: {
            language() {
                return this.$lang;
            },
            projects() {
                return [{
                        id: '8',
                        title: 'Syncro',
                        link: 'https://syncromsp.com/',
                        status: '',
                        code: 'syncro',
                        img: 'syncro-shape.png',
                    },
                    {
                        id: '7',
                        title: 'Watchman Monitoring',
                        link: 'https://www.watchmanmonitoring.com/',
                        status: '',
                        code: 'watchman',
                        img: 'watchman-shape.png',
                    },
                    {
                        id: '3',
                        title: 'Numesia',
                        link: 'http://numesia.fr',
                        code: 'numesia',
                    },
                ]
            },
            subProjects() {
                return [{
                        id: '2',
                        title: 'e-services Mulhouse',
                        link: 'https://e-services.mulhouse-alsace.fr/',
                        status: '',
                        code: 'mulhouse',
                        img: 'gen-home-signature.png',
                    },
                    {
                        id: '1',
                        title: 'e-services Colmar',
                        link: 'https://eservices.portail.colmar.fr/',
                        status: '',
                        code: 'colmar',
                        img: 'logo_colmaretmoi.png',
                    },
                    {
                        id: '5',
                        title: 'Rapo Montfermeil',
                        link: 'https://montfermeil.rapo-fps.fr/rapo-fps',
                        status: '',
                        code: 'cloud montfermeil',
                        img: 'gen-home-signature.png',
                    },
                    {
                        id: '6',
                        title: 'Rapo Orsay',
                        link: 'https://orsay.rapo-fps.fr/rapo-fps',
                        status: '',
                        code: 'cloud orsay',
                        img: 'gen-home-signature.png',
                    },
                ]
            },
            designs() {
                return [{
                        id: '9',
                        title: 'De Padres a Hijos',
                        code: 'padres',
                        link: "https://www.youtube.com/channel/UCl_X6QI3mnJsttsp96OsCZQ",
                    },
                    {
                        id: '10',
                        title: 'Transport Tourall',
                        code: 'tourall',
                        whiteBg: true,
                        link: 'https://www.facebook.com/transporttourall/',
                    },
                    {
                        id: '11',
                        title: 'La Cigogne Enragée',
                        code: 'cigogne',
                        link: "https://www.facebook.com/La-Cigogne-Enrag%C3%A9e-366860950096067/",
                    },
                    {
                        title: 'Late',
                        code: 'late',
                        description: 'Logo',
                    },
                    {
                        title: 'Dozens',
                        code: 'dozens',
                        description: 'CD Cover',
                    },
                    {
                        title: 'Zadig le chien',
                        code: 'zlc',
                        whiteBg: true,
                        description: 'T-shirt',
                    },
                    {
                        title: 'Gator',
                        code: 'gator',
                        description: 'Fake Logo',
                    },
                    {
                        title: 'Kama CCD',
                        code: 'kama',
                        whiteBg: true,
                        description: 'Logo',
                    },
                    {
                        title: 'Joker',
                        code: 'joker',
                        description: 'T-shirt',
                    },
                    {
                        title: 'Dotsay',
                        code: 'dotsay',
                        description: 'Design',
                    },
                    {
                        title: 'The Empty Cage',
                        code: 'cage',
                        whiteBg: true,
                        description: 'T-shirt',
                    },
                    {
                        title: 'Marble',
                        code: 'marble',
                        description: 'Design',
                    },
                ]
            },
        },
    }
</script>