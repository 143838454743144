<template>
    <div class="content-me">
        <div class="content">
            <h2>
                <span v-html="$t('me_title')"></span>
                <span class="icons View-2"></span>
            </h2>
            <div>
                <div class="card me large">
                    <div class="card-title">
                        <h3 v-html="$t('me_hi')"></h3>
                    </div>
                    <div class="card-content">
                        <p v-html="$t('me_content')"></p>
                    </div>
                    <div class="card-footer"></div>
                </div>
            </div>
            <div class="btn-send-me-container">
                <div>
                    <a href="https://www.linkedin.com/in/antonylourenco/" target="_blank" class="btn-send-me next inline">
                        <span class="text" v-html="$t('next')"></span>
                        <span class="icons Arrow"></span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {

    }
</script>