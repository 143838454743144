<template>
    <div>
        <div class="content-subtitle" :class="showTool ? 'active' : ''" @click="toggleTool">
            <h3>
                <span class="icons" :class="icon"></span>
                <span class="text" v-html="$t(`${categorieTitle}`)"></span>
            </h3>
            <div class="icons Arrow-2" :class="showTool ? 'down' : ''"></div>
            <div v-if="showTool" class="content-section tool-bloc">
                <tool v-for="(tool, index, key) in toggle" :key="key" :tool="tool"></tool>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: ["toggle", 'index'],
        mounted() {
            this.updateToggle;
        },
        data() {
            return {
                showTool: false,
            }
        },
        methods: {
            toggleTool() {
                this.showTool = !this.showTool;
            },
        },
        computed: {
            categorieTitle() {
                return 'projects_categorie_' + Number(this.index + 1);
            },
            updateToggle() {
                if (this.index == 0) {
                    this.showTool = true;
                }
            },
            icon() {
                if (this.index == 0) {
                    return 'Share-1'
                }
                if (this.index == 1) {
                    return 'Mail'
                }
                if (this.index == 2) {
                    return 'Palette'
                }
                if (this.index == 3) {
                    return 'Idea'
                }
                if (this.index == 4) {
                    return 'Galaxy'
                }
            }
        },
    }
</script>
