<template>
	<div>
		<div class="container text-center">

			<svg width="100%" height="100%" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" class="draw-line-container-1">
				<!-- Define gradient for each rectangle -->
				<defs>
					<linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
						<stop offset="0%" style="stop-color:#FF6347; stop-opacity:1" />
						<stop offset="25%" :style="'stop-color:' + colorOne + 'stop-opacity:1;'" />
						<stop offset="50%" :style="'stop-color:' + colorOne + 'stop-opacity:1;'" />
						<stop offset="75%" :style="'stop-color:' + colorOne + 'stop-opacity:1;'" />
						<stop offset="100%" style="stop-color:#FF6347; stop-opacity:1" />
					</linearGradient>

					<linearGradient id="grad2" x1="0%" y1="0%" x2="0%" y2="100%">
						<stop offset="0%" style="stop-color:#4682B4; stop-opacity:1" />
						<stop offset="25%" :style="'stop-color:' + colorOne + 'stop-opacity:1;'" />
						<stop offset="50%" :style="'stop-color:' + colorOne + 'stop-opacity:1;'" />
						<stop offset="75%" :style="'stop-color:' + colorOne + 'stop-opacity:1;'" />
						<stop offset="100%" style="stop-color:#4682B4; stop-opacity:1" />
					</linearGradient>

					<linearGradient id="grad3" x1="0%" y1="0%" x2="0%" y2="100%">
						<stop offset="0%" style="stop-color:#32CD32; stop-opacity:1" />
						<stop offset="25%" :style="'stop-color:' + colorOne + 'stop-opacity:1;'" />
						<stop offset="50%" :style="'stop-color:' + colorOne + 'stop-opacity:1;'" />
						<stop offset="75%" :style="'stop-color:' + colorOne + 'stop-opacity:1;'" />
						<stop offset="100%" style="stop-color:#32CD32; stop-opacity:1" />
					</linearGradient>

					<linearGradient id="grad4" x1="0%" y1="0%" x2="0%" y2="100%">
						<stop offset="0%" style="stop-color:#FFD700; stop-opacity:1" />
						<stop offset="25%" :style="'stop-color:' + colorOne + 'stop-opacity:1;'" />
						<stop offset="50%" :style="'stop-color:' + colorOne + 'stop-opacity:1;'" />
						<stop offset="75%" :style="'stop-color:' + colorOne + 'stop-opacity:1;'" />
						<stop offset="100%" style="stop-color:#FFD700; stop-opacity:1" />
					</linearGradient>

					<linearGradient id="grad5" x1="0%" y1="0%" x2="0%" y2="100%">
						<stop offset="0%" style="stop-color:#8A2BE2; stop-opacity:1" />
						<stop offset="25%" :style="'stop-color:' + colorOne + 'stop-opacity:1;'" />
						<stop offset="50%" :style="'stop-color:' + colorOne + 'stop-opacity:1;'" />
						<stop offset="75%" :style="'stop-color:' + colorOne + 'stop-opacity:1;'" />
						<stop offset="100%" style="stop-color:#8A2BE2; stop-opacity:1" />
					</linearGradient>

					<linearGradient id="grad6" x1="0%" y1="0%" x2="0%" y2="100%">
						<stop offset="0%" style="stop-color:#FF4500; stop-opacity:1" />
						<stop offset="25%" :style="'stop-color:' + colorOne + 'stop-opacity:1;'" />
						<stop offset="50%" :style="'stop-color:' + colorOne + 'stop-opacity:1;'" />
						<stop offset="75%" :style="'stop-color:' + colorOne + 'stop-opacity:1;'" />
						<stop offset="100%" style="stop-color:#FF4500; stop-opacity:1" />
						<stop offset="100%" :style="'stop-color:' + colorOne + 'stop-opacity:1;'" />
					</linearGradient>

				</defs>

				<!-- Use gradients for the rectangles -->
				<rect x="0" y="0" width="16.66" height="100" class="draw-line-1" fill="url(#grad1)" />
				<rect x="16.66" y="0" width="16.66" height="100" class="draw-line-1" fill="url(#grad2)" />
				<rect x="33.32" y="0" width="16.66" height="100" class="draw-line-1" fill="url(#grad3)" />
				<rect x="49.98" y="0" width="16.66" height="100" class="draw-line-1" fill="url(#grad4)" />
				<rect x="66.64" y="0" width="16.66" height="100" class="draw-line-1" fill="url(#grad5)" />
				<rect x="83.3" y="0" width="16.66" height="100" class="draw-line-1" fill="url(#grad6)" />
			</svg>


			<h1 class="signature-container">
				<div>
					<div class="AL-font A"></div>
					<span class="AL-font ntony">NTONY</span>
				</div>
				<div>
					<div class="AL-font L"></div>
					<span class="AL-font ourenco">OURENCO</span>
				</div>
			</h1>
			<h2 class="web-int">
				<span v-html="$t('signature_integration')" :data-text="$t('signature_integration')"></span>
				<span class="and" v-html="$t('signature_and')" :data-text="$t('signature_and')"></span>
				<span v-html="$t('signature_webdesign')" :data-text="$t('signature_webdesign')"></span>
			</h2>
		</div>
	</div>
</template>

<script>
	import {
		inject,
		ref
	} from 'vue'
	export default {
		setup() {
			const {
				alTheme
			} = inject('alTheme', {
				alTheme: ref('dark')
			});
			return {
				alTheme
			};
		},
		computed: {
			colorOne() {
				if (this.alTheme === "dark") {
					return "rgba(0,0,0);";
				} else {
					return "rgba(255,255,255);";
				}
			},
		},
	}
</script>
