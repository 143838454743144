<template>
    <div :class="[alTheme + '-theme', isScrolling ? 'user-scrolling' : '']">
        <div class="main-container-content">
            <div class="main-container-content-image">

                <mainmenu @updateTheme="changeTheme" @updateLanguage="changeLanguage"></mainmenu>

                <signature></signature>

            </div>

            <div class="content-container" id="content">

                <RouterView />

            </div>

            <mainfooter></mainfooter>

        </div>
    </div>
</template>
<script>
    import { useI18n } from 'vue-i18n';
    export default {
        setup() {
            const { locale } = useI18n();

            const changeLanguage = (value) => {
                locale.value = value;
            };

            return {
                changeLanguage
            };
        },
        data(){
            return {
                alTheme: "dark",
                alLanguage: "en",
                isScrolling: false,
            }
        },
        mounted() {
            const storedtheme = localStorage.getItem('al-theme');
            const storedLanguage = localStorage.getItem('al-language');
            if (storedtheme) {
                this.changeTheme(storedtheme);
            }
            if (storedLanguage) {
                this.changeLanguage(storedLanguage);
            }
            // Add scroll event listener
            window.addEventListener('scroll', this.handleScroll);
        },
        beforeUnmount() {
            // Remove scroll event listener when component is destroyed
            window.removeEventListener('scroll', this.handleScroll);
        },
        methods:  {
            changeTheme(value) {
                this.alTheme = value;
            },
            handleScroll() {
                // Set isScrolling to true when the user scrolls
                this.isScrolling = true;

                // Clear the previous timeout if it exists
                if (this.scrollTimeout) {
                    clearTimeout(this.scrollTimeout);
                }

                // Check if user as reached skills section
                const skillsCardContainer = document.querySelector('.skills-card-container');
                const linkSkills = document.querySelector('.link-skills');

                if (skillsCardContainer && linkSkills) {
                    const rect = skillsCardContainer.getBoundingClientRect();

                    const isInViewport = rect.top < window.innerHeight;

                    if (isInViewport) {
                        linkSkills.classList.add('router-link-active');
                    } else {
                        linkSkills.classList.remove('router-link-active');
                    }
                }
                else {
                    linkSkills.classList.remove('router-link-active');
                }

                // Set a timeout to remove the isScrolling flag after scrolling stops
                this.scrollTimeout = setTimeout(() => {
                    this.isScrolling = false;
                }, 200); // Adjust the delay as needed
            }
        },
    }
</script>
